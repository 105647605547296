import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

import { SimpleHeading } from '../components/SimpleHeading';
import { NewsList } from '../components/News/List';
import { DefaultLayout } from '../layouts/Default';

const AktuellesPage = ({ data, location }) => {
  const { site, content, news } = data;
  const { opengraph, metadata } = content;
  const { siteMetadata } = site;
  return (
    <DefaultLayout>
      <SimpleHeading title="Aktuelles" />
      <NewsList entries={news.edges} />
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default AktuellesPage;

export const AktuellesPageQuery = graphql`
  query AktuellesPageQuery {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    content: cockpitRegionAktuelles {
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
    news: allCockpitNews(filter: { published: { eq: true } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          teaser
          text
          date
          published
          expirationDate
          noDetailPage
          images {
            childImageSharp {
              gatsbyImageData(width: 640)
            }
          }
          preview {
            previewTitle
            previewText
            previewImage {
              childImageSharp {
                gatsbyImageData(width: 640)
              }
            }
          }
        }
      }
    }
  }
`;
