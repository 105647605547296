import React from 'react';
import clsx from 'clsx';

import { ListEntry } from './ListEntry';

import * as styles from './List.module.css';

function sortByDate(entry1, entry2) {
  return entry2.node.date * 1000 - entry1.node.date * 1000;
}

function filterExpired(node) {
  if (node.node.expirationdate <= 0) {
    return node;
  }

  return node.node.expirationdate * 1000 < new Date().getTime() ? null : node;
}

export function NewsList({ entries }) {
  return (
    <div className={clsx(styles.content, 'defaultWidth')}>
      {entries
        .sort(sortByDate)
        .filter(filterExpired)
        .map(({ node }) => {
          return <ListEntry key={node.id} entry={node} />;
        })}
    </div>
  );
}
