import React from 'react';
import Link from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import fecha from 'fecha';
import { uniqueSlug } from '@mangoart/gatsby-ui/helper/Slugger';

import { navigate } from 'gatsby';

import * as styles from './ListEntry.module.css';
import { ButtonLink } from '../ButtonLink';

export function ListEntry({ entry }) {
  if (!entry || !(entry.images || entry.title || entry.text) || !entry.preview || !entry.published) {
    return null;
  }
  const image = entry.preview.previewImage ? entry.preview.previewImage : entry.images[0];
  const text = entry.preview.previewText ? entry.preview.previewText : entry.teaser ? entry.teaser : entry.text;
  const title = entry.preview.previewTitle ? entry.preview.previewTitle : entry.title;
  const publishDate = new Date(entry.date);

  const linkToArticle = `/aktuelles/${uniqueSlug(entry.title, fecha.format(new Date(publishDate), 'D.M.YYYY'))}`;

  return (
    <div className={styles.content} onClick={() => navigate(linkToArticle)}>
      <div className={styles.leftColumn}>
        <Link to={linkToArticle} title={title}>
          <GatsbyImage image={getImage(image)} alt={title} style={{ width: '100%' }} />
        </Link>
      </div>
      <div className={styles.middleColumn}>
        <div className={styles.middleColumnContainer}>
          <div className={styles.date}>{fecha.format(publishDate, 'D.M.YYYY')}</div>
          <h3 className={styles.title}>
            <Link to={linkToArticle}>{title}</Link>
          </h3>
          {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
        </div>
      </div>
      <div className={styles.rightColumn}>
        <ButtonLink
          href={linkToArticle}
          style={{
            padding: '0.5em 2em 0.5em 1em',
          }}
        >
          Weiterlesen
        </ButtonLink>
      </div>
    </div>
  );
}
